@mixin themable($theme-name, $text-danger-color, $card-background-color) {
    .#{$theme-name} {
      .text-danger, .text-danger:hover{
          color: $text-danger-color !important;
      }

      .invalid-feedback {
          color: $text-danger-color !important;
      }

      .card-register {
        background-color: $card-background-color !important;
        border-radius: 8px;
        color: #fff;
        max-width: 350px;
        margin: 20px 0 70px;
        min-height: 400px;
        padding: 30px;
        font-weight: bold;
      
        & label {
          margin-top: 15px;
        }
        & .card-title {
          color: #B33C12;
          text-align: center;
        }
        & .btn {
          margin-top: 30px;
        }
        & .forgot {
          text-align: center;
        }
      
        .input-group-text {
          border: 1px solid $white-color;
        }
      }
    }
 }