@font-face {
    font-family: 'Quicksand';
    src: url('~assets/fonts/Quicksand-VariableFont_wght.ttf');    
    font-weight: normal;
    font-style: normal;
}


.quicksand {
    font-family: 'Quicksand';
}

.white {
    color: #fff;
}

.red {
    color: #b95042; 
}

.green {
    color: #3b673e;
}

.renew-blue {
    color: #417896;
}

.renew-gold {
    color: #AF8835;
}

.renew-green {
    color: #3C683F;
}

.pro_green {
    color: #59932b;
}

.pro_blue {
    color: #6cadc6;
}

.renew-pink {
    color: #FCEFE9;
}

.blue {
    color: #1d3d59;
}

.heavy {
    font-weight: 600;
}

.light {
    font-weight: 300;
}

.no-margin {
    margin: 0;
}